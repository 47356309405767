<template>
    <a v-if="to" :href="to" class="base-btn transition" :class="classes" @click="sendGaEvent">
        <slot />
    </a>

    <button v-else type="button" class="base-btn transition" :class="classes" @click="sendGaEvent">
        <slot />
    </button>
</template>

<script>
export default {
    name: "BaseButton",
    props: {
      to: {
        type: String,
      },
      type: {
        type: String,
      },
      size: {
        type: String,
      },
      iconPosition: {
        type: String,
      },
      gaEvent: {
        type: String,
        required: false,
        default: null
      },
    },
  computed: {
      classes () {
        return [this.type, this.size, `icon-${this.iconPosition}`]
      }
  },
  methods: {
      sendGaEvent() {
        if (window.hasOwnProperty('gtag') && this.gaEvent) {
          window.gtag('event', this.gaEvent,
            {
                device: window.navigator.userAgent
            });
          }
        }
    }
  }
</script>
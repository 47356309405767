import moment from "moment";
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import axios from "axios";

// initial state
const state = {
    urls: [],
    histogram: {
        totalFields: ['sum_views', 'sum_games', 'sum_videos', 'sum_postroll_views', 'sum_preroll_views', 'sum_header_views'],
        loading: false,
        data: null,
        labels: []
    },
    statisticsFilters: {
        league: null,
        country: null,
        sport: null,
        channel: null,
        club: null,
        interval: 'day',
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
    },
    filtersMap: null,
    accountLevelType: null,
    accountRole: null,
};

// getters
const getters = {
    getFilters(state) {
        return (apiName) => {
            let itemsToPick = state.filtersMap[apiName];
            let picked = pick(state.statisticsFilters, Object.keys(itemsToPick));
            let result = {};
            Object.entries(picked).forEach(entry => {
                let key = entry[0];
                let value = entry[1];
                if (value != null) {
                    result[itemsToPick[key]] = value;
                }
            });
            return result
        }
    },
    getHistogramData(state) {
        if(state.histogram.data) {
            return Object.keys(state.histogram.data)
                .filter(key => !state.histogram.totalFields.includes(key))
                .reduce((obj, key) => {
                    obj[key] = state.histogram.data[key];
                    return obj;
                }, {});
        }else{
            return null;
        }
    },
    getHistogramTotalStats(state) {
        if(state.histogram.data) {
            return Object.keys(state.histogram.data)
                .filter(key => state.histogram.totalFields.includes(key))
                .reduce((obj, key) => {
                    obj[key] = state.histogram.data[key];
                    return obj;
                }, {});
        }else {
            return null;
        }
    }
};

// actions
const actions = {
    fetchHistogram({state, getters, commit, dispatch}) {
        if (!state.histogram.loading) {
            commit('setHistogramLoading', true);
            const query = getters['getFilters']('api:elastic-api-histogram');
            axios.post(state.urls['api:elastic-api-histogram'](), query).then(response => {
                commit('setHistogramLoading', false);
                if (isEqual(query, getters['getFilters']('api:elastic-api-histogram')))
                    commit('setHistogramData', response.data);
                else
                    dispatch('fetchHistogram');
            });
        }
    }
};

// mutations
const mutations = {
    setHistogramLoading(state, load_state) {
        state.histogram.loading = load_state;
    },
    setUrls(state, urls) {
        state.urls = urls;
    },
    setAccountLevelType(state, accountLevelType) {
        state.accountLevelType = accountLevelType;
    },
    setAccountRole(state, accountRole) {
        state.accountRole = accountRole;
    },
    setLanguages(state, languages) {
        state.languages = Object.keys(languages).map((key) => {
            return {
                id: key,
                name: languages[key]
            }
        });
    },
    setCurrencies(state, currencies){
        state.currencies = Object.keys(currencies).map((key) => {
            return {
                id: key,
                name: currencies[key]
            }
        });
    },
    setFiltersMap(state, filtersMap) {
        state.filtersMap = filtersMap;
    },
    setDateRange(state, {startDate, endDate}) {
        state.dateRange.startDate = startDate;
        state.dateRange.endDate = endDate;
    },
    setHistogramData(state, data) {
        state.histogram.data = data;
    },
    setgamesPerDate(state, data) {
        state.gamesPerDate = data;
    },
    setFilter(state, data) {
        state.statisticsFilters = {
            ...state.statisticsFilters,
            ...data
        };
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<script>
export default {
  name: "PricingSection",
  props: {
    region: {
      type: Array,
      required: false
    },
  },
  data() {
    return {
      level2monthly: [
        {
          show: true,
          plan: 'Free World',
          info: 'No commitment, basic features',
          period: 'month',
          billing: 'free for everyone',
          price: 0,
          link: {
            label: 'Get Started with Free World',
            slug: 'registration'
          },
          top_benefits: [
            {
              text: 'Up to <strong>10 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>1 channel</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: 'Up to <strong>3 individual users</strong>'
            }
          ],
          other_benefits: [
              {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: true
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €25'
            },
            {
              title: 'Saving to your device',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: true
                }
              ]
            },

            {
              title: 'Ads',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: true
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: true
                }
              ]
            },
            {
              title: 'Other',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: false
                },
                {
                  text: 'Highlight length editor',
                  disabled: false
                },

                {
                  text: 'Donate button',
                  disabled: false
                },

                {
                  text: 'Video download button for fans',
                  disabled: false
                },

                {
                  text: 'Private channels',
                  disabled: true
                }
              ]
            }
          ]
        },
        {
          show: true,
          plan: 'Entry World',
          info: 'More channels, more features',
          period: 'month',
          billing: 'billed monthly',
          price: 19,
          link: {
            label: 'Get Started with Entry World',
            slug: 'registration/price_1P6qctCQhcfhwGyfCso3TalC'
          },
          top_benefits: [
            {
              text: 'Up to <strong>20 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>3 channels</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: 'Up to <strong>10 individual users</strong>'
            }
          ],
          other_benefits: [
              {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: true
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €25'
            },
            {
              title: 'Saving to your device',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: false
                }
              ]
            },

            {
              title: 'Ads',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: false
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: false
                }
              ]
            },
            {
              title: 'Other',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: false
                },
                {
                  text: 'Highlight length editor',
                  disabled: false
                },

                {
                  text: 'Donate button',
                  disabled: false
                },

                {
                  text: 'Video download button for fans',
                  disabled: false
                },

                {
                  text: 'Private channels',
                  disabled: false
                }
              ]
            }
          ]
        },
        {
          show: true,
          plan: 'Premium World',
          info: 'Custom streaming, top-tier features',
          period: 'month',
          billing: 'billed monthly',
          price: 62,
          link: {
            label: 'Get Started with Premium World',
            slug: 'registration/price_1P6qdACQhcfhwGyfcY1t6DwA'
          },
          top_benefits: [
            {
              text: 'Up to <strong>100 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>Unlimited channels</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: '<strong>Unlimited users</strong>'
            }
          ],
          other_benefits: [
              {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: false
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €25'
            },
            {
              title: 'Saving to your device',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: false
                }
              ]
            },

            {
              title: 'Ads',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: false
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: false
                }
              ]
            },
            {
              title: 'Other',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: false
                },
                {
                  text: 'Highlight length editor',
                  disabled: false
                },

                {
                  text: 'Donate button',
                  disabled: false
                },

                {
                  text: 'Video download button for fans',
                  disabled: false
                },

                {
                  text: 'Private channels',
                  disabled: false
                }
              ]
            }
          ]
        }
      ],

      level2yearly: [
        {
          show: true,
          plan: 'Free World',
          info: 'No commitment, basic features',
          period: 'month',
          billing: 'free',
          price: 0,
          link: {
            label: 'Get Started with Free World',
            slug: 'registration'
          },
          top_benefits: [
            {
              text: 'Up to <strong>10 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>1 channel</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: 'Up to <strong>3 individual users</strong>'
            }
          ],
          other_benefits: [
              {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: true
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €25'
            },
            {
              title: 'Saving to your device',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: true
                }
              ]
            },

            {
              title: 'Ads',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: true
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: true
                }
              ]
            },
            {
              title: 'Other',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: false
                },
                {
                  text: 'Highlight length editor',
                  disabled: false
                },

                {
                  text: 'Donate button',
                  disabled: false
                },

                {
                  text: 'Video download button for fans',
                  disabled: false
                },

                {
                  text: 'Private channels',
                  disabled: true
                }
              ]
            }
          ]
        },
        {
          show: true,
          plan: 'Entry World',
          info: 'More channels, more features',
          period: 'month',
          billing: '<strong>€96</strong> billed yearly',
          price: 8,
          link: {
            label: 'Get Started with Entry World',
            slug: 'registration/price_1P6qcWCQhcfhwGyfA9wUt1ZG'
          },
          top_benefits: [
            {
              text: 'Up to <strong>20 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>3 channels</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: 'Up to <strong>10 individual users</strong>'
            }
          ],
          other_benefits: [
              {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: true
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €25'
            },
            {
              title: 'Saving to your device',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: false
                }
              ]
            },

            {
              title: 'Ads',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: false
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: false
                }
              ]
            },
            {
              title: 'Other',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: false
                },
                {
                  text: 'Highlight length editor',
                  disabled: false
                },

                {
                  text: 'Donate button',
                  disabled: false
                },

                {
                  text: 'Video download button for fans',
                  disabled: false
                },

                {
                  text: 'Private channels',
                  disabled: false
                }
              ]
            }
          ]
        },
        {
          show: true,
          plan: 'Premium World',
          info: 'Custom streaming, top-tier features',
          period: 'month',
          billing: '<strong>€312</strong> billed yearly',
          price: 26,
          link: {
            label: 'Get Started with Premium World',
            slug: 'registration/price_1P6qdVCQhcfhwGyfnJe04VbD'
          },
          top_benefits: [
            {
              text: 'Up to <strong>100 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>Unlimited channels</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: '<strong>Unlimited users</strong>'
            }
          ],
          other_benefits: [
              {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: false
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €25'
            },
            {
              title: 'Saving to your device',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: false
                }
              ]
            },

            {
              title: 'Ads',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: false
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: false
                }
              ]
            },
            {
              title: 'Other',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: false
                },
                {
                  text: 'Highlight length editor',
                  disabled: false
                },

                {
                  text: 'Donate button',
                  disabled: false
                },

                {
                  text: 'Video download button for fans',
                  disabled: false
                },

                {
                  text: 'Private channels',
                  disabled: false
                }
              ]
            }
          ]
        }
      ],

      level01monthly: [
        {
          show: true,
          plan: 'Free',
          info: 'No commitment, basic features',
          period: 'month',
          billing: 'free for everyone',
          price: 0,
          link: {
            label: 'Get Started with Free',
            slug: 'registration'
          },
          top_benefits: [
            {
              text: 'Up to <strong>3 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>1 channel</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: '<strong>1 individual user</strong>'
            }
          ],
          other_benefits: [
            {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: true
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €26'
            },
            {
              title: 'Saving to your device',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: true
                }
              ]
            },

            {
              title: 'Ads',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: true
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: true
                }
              ]
            },
            {
              title: 'Other',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: true
                },
                {
                  text: 'Highlight length editor',
                  disabled: true
                },

                {
                  text: 'Donate button',
                  disabled: true
                },

                {
                  text: 'Video download button for fans',
                  disabled: true
                },

                {
                  text: 'Private channel',
                  disabled: true
                }
              ]
            }
          ]
        },
        {
          show: true,
          plan: 'Entry',
          info: 'More games, more features',
          period: 'month',
          billing: 'billed monthly',
          price: 19,
          link: {
            label: 'Get Started with Entry',
            slug: 'registration/price_1NdTgECQhcfhwGyfosTF7HzD'
          },
          top_benefits: [
            {
              text: 'Up to <strong>10 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>1 channel</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: 'Up to <strong>3 individual users</strong>'
            }
          ],
          other_benefits: [
            {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: true
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €26'
            },
            {
              title: 'Saving to your device',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: true
                }
              ]
            },

            {
              title: 'Ads',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: false
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: true
                }
              ]
            },
            {
              title: 'Other',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: false
                },
                {
                  text: 'Highlight length editor',
                  disabled: false
                },

                {
                  text: 'Donate button',
                  disabled: false
                },

                {
                  text: 'Video download button for fans',
                  disabled: false
                },

                {
                  text: 'Private channel',
                  disabled: false
                }
              ]
            }
          ]
        },
        {
          show: true,
          plan: 'Premium',
          info: 'Your sponsors, more channels',
          period: 'month',
          billing: 'billed monthly',
          price: 65,
          link: {
            label: 'Get Started with Premium',
            slug: 'registration/price_1PTKrdCQhcfhwGyffLd0VHWM'
          },
          top_benefits: [
            {
              text: 'Up to <strong>20 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>3 channels</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: 'Up to <strong>10 individual users</strong>'
            }
          ],
          other_benefits: [
            {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: true
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €26'
            },
            {
              title: 'Saving to your device',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: false
                }
              ]
            },

            {
              title: 'Ads',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: false
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: false
                }
              ]
            },
            {
              title: 'Other',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: false
                },
                {
                  text: 'Highlight length editor',
                  disabled: false
                },

                {
                  text: 'Donate button',
                  disabled: false
                },

                {
                  text: 'Video download button for fans',
                  disabled: false
                },

                {
                  text: 'Private channels',
                  disabled: false
                }
              ]
            }
          ]
        },
        {
          show: true,
          plan: 'Ultra',
          info: 'Custom streaming, top-trier features',
          period: 'month',
          billing: 'billed monthly',
          price: 247,
          link: {
            label: 'Get Started with Ultra',
            slug: 'registration/price_1PTKspCQhcfhwGyfxh77EjqY'
          },
          top_benefits: [
            {
              text: 'Up to <strong>100 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>Unlimited channels</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: '<strong>Unlimited users</strong>'
            }
          ],
          other_benefits: [
            {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: false
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €26'
            },
            {
              title: 'Saving to your device',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: false
                }
              ]
            },

            {
              title: 'Ads',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: false
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: false
                }
              ]
            },
            {
              title: 'Other',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: false
                },
                {
                  text: 'Highlight length editor',
                  disabled: false
                },

                {
                  text: 'Donate button',
                  disabled: false
                },

                {
                  text: 'Video download button for fans',
                  disabled: false
                },

                {
                  text: 'Private channels',
                  disabled: false
                }
              ]
            }
          ]
        }
      ],

      level01yearly: [
        {
          show: true,
          plan: 'Free',
          info: 'No commitment, basic features',
          period: 'month',
          billing: 'free for everyone',
          price: 0,
          link: {
            label: 'Get Started with Free',
            slug: 'registration'
          },
          top_benefits: [
            {
              text: 'Up to <strong>3 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>1 channel</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: '<strong>1 individual user</strong>'
            }
          ],
          other_benefits: [
              {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: true
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €26'
            },
            {
              title: 'Saving to your device',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: true
                }
              ]
            },

            {
              title: 'Ads',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: true
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: true
                }
              ]
            },
            {
              title: 'Other',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: true
                },
                {
                  text: 'Highlight length editor',
                  disabled: true
                },

                {
                  text: 'Donate button',
                  disabled: true
                },

                {
                  text: 'Video download button for fans',
                  disabled: true
                },

                {
                  text: 'Private channel',
                  disabled: true
                }
              ]
            }
          ]
        },
        {
          show: true,
          plan: 'Entry',
          info: 'More games, more features',
          period: 'month',
          billing: '<strong>€96</strong> billed yearly',
          price: 8,
          link: {
            label: 'Get Started with Entry',
            slug: 'registration/price_1NdTgDCQhcfhwGyfFyGwXjho'
          },
          top_benefits: [
            {
              text: 'Up to <strong>10 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>1 channel</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: 'Up to <strong>3 individual users</strong>'
            }
          ],
          other_benefits: [
              {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: true
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €26'
            },
            {
              title: 'Saving to your device',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: true
                }
              ]
            },

            {
              title: 'Ads',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: false
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: true
                }
              ]
            },
            {
              title: 'Other',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: false
                },
                {
                  text: 'Highlight length editor',
                  disabled: false
                },

                {
                  text: 'Donate button',
                  disabled: false
                },

                {
                  text: 'Video download button for fans',
                  disabled: false
                },

                {
                  text: 'Private channel',
                  disabled: false
                }
              ]
            }
          ]
        },
        {
          show: true,
          plan: 'Premium',
          info: 'Your sponsors, more channels',
          period: 'month',
          billing: '<strong>€312</strong> billed yearly',
          price: 26,
          link: {
            label: 'Get Started with Premium',
            slug: 'registration/price_1PTKsNCQhcfhwGyfnxR7lOCs'
          },
          top_benefits: [
            {
              text: 'Up to <strong>20 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>3 channels</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: 'Up to <strong>10 individual users</strong>'
            }
          ],
          other_benefits: [
              {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: true
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €26'
            },
            {
              title: 'Saving to your device',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: false
                }
              ]
            },

            {
              title: 'Ads',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: false
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: false
                }
              ]
            },
            {
              title: 'Other',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: false
                },
                {
                  text: 'Highlight length editor',
                  disabled: false
                },

                {
                  text: 'Donate button',
                  disabled: false
                },

                {
                  text: 'Video download button for fans',
                  disabled: false
                },

                {
                  text: 'Private channels',
                  disabled: false
                }
              ]
            }
          ]
        },
        {
          show: true,
          plan: 'Ultra',
          info: 'Custom streaming, top-trier features',
          period: 'month',
          billing: '<strong>€1188</strong> billed yearly',
          price: 99,
          link: {
            label: 'Get Started with Ultra',
            slug: 'registration/price_1PTKtLCQhcfhwGyfUo2hhq4o'
          },
          top_benefits: [
            {
              text: 'Up to <strong>100 games</strong> per month'
            },
            {
              text: '<strong>Unlimited live streaming</strong> to <strong>YouTube</strong>, <strong>Facebook</strong> or <strong>Twitch</strong>'
            },
            {
              text: '<strong>Unlimited highlights</strong>'
            },
            {
              text: '<strong>Unlimited channels</strong> with <strong>unlimited teams</strong> and <strong>competitions</strong>'
            },
            {
              text: '<strong>Unlimited users</strong>'
            }
          ],
          other_benefits: [
              {
              title: 'MORE LIVE STREAMING OPTIONS',
              height: '60px',
              items: [
                {
                  text: 'Live stream to your custom studio, multiple platforms or own streaming server',
                  disabled: false
                },
                {
                  text: 'Live stream directly to your Sport.Video channel',
                  disabled: false
                }
              ],
              additional_text: '2 livestreams and 200 hours of live watch time for just €26'
            },
            {
              title: 'Saving to your device',
              height: '75px',
              items: [
                {
                  text: 'Highlights saved on your device',
                  disabled: false
                },
                {
                  text: 'Full-game saved on your device<br>',
                  disabled: false
                }
              ]
            },
            {
              title: 'Ads',
              height: '75px',
              items: [
                {
                  text: 'No Video Ads',
                  disabled: false
                },
                {
                  text: 'Add Your Sponsors to Videos',
                  disabled: false
                }
              ]
            },
            {
              title: 'Other',
              height: '100px',
              items: [
                {
                  text: 'Record games with multiple smartphones',
                  disabled: false
                },
                {
                  text: 'Highlight length editor',
                  disabled: false
                },

                {
                  text: 'Donate button',
                  disabled: false
                },

                {
                  text: 'Video download button for fans',
                  disabled: false
                },

                {
                  text: 'Private channels',
                  disabled: false
                }
              ]
            }
          ]
        }
      ],

      customPricingData: {
        description:
            ' The Custom Plan is designed to meet your unique needs, offering personalized features, enhanced storage, and specialized support for a perfect fit.',
        benefits: [
          {
            text: 'Any limit available'
          },
          {
            text: 'White-label solution'
          },
          {
            text: 'API access'
          },
          {
            text: 'Dedicated broadcasting crew'
          },
          {
            text: 'Custom branding'
          },
          {
            text: 'Enhanced technical support'
          },
          {
            text: 'Elevated priority support'
          },
          {
            text: 'Custom features'
          }
        ]
      }
    }
  }
}
</script>

<template>
    <section>
        <div class="container">
            <div class="row justify-content-center mb-2">
                <div class="col-60 col-lg-30">
                    <h1 class="fw-semibold text-center mb-2">Compare All the Benefits</h1>
                    <p class="text-center">
                        Not sure which plan to choose? This breakdown should help you select the one
                        just right for you.
                    </p>
                </div>
            </div>

            <div class="d-flex mb-2">
                <ul class="pricing-plan-switcher mx-auto d-flex" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button
                            id="annually-tab"
                            class="active fw-semibold"
                            data-bs-toggle="tab"
                            data-bs-target="#annually"
                            type="button"
                            role="tab"
                            aria-controls="annually"
                            aria-selected="true"
                        >
                            Annually
                            <span class="fw-semibold">60% off</span>
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button
                            id="monthly-tab"
                            data-bs-toggle="tab"
                            class="fw-semibold"
                            data-bs-target="#monthly"
                            type="button"
                            role="tab"
                            aria-controls="monthly"
                            aria-selected="false"
                        >
                            Monthly
                        </button>
                    </li>
                </ul>
            </div>

            <div class="tab-content mb-2">
                <div
                    id="annually"
                    class="tab-pane fade active show"
                    role="tabpanel"
                    aria-labelledby="annually-tab"
                    tabindex="0"
                >
                    <PricingBoxes :data="region && region.includes('level_2') ? level2yearly : level01yearly" />
                </div>
                <div
                    id="monthly"
                    class="tab-pane fade"
                    role="tabpanel"
                    aria-labelledby="monthly-tab"
                    tabindex="0"
                >
                  <PricingBoxes :data="region && region.includes('level_2') ? level2monthly : level01monthly" />
                </div>
            </div>

            <CustomPricingBox :data="customPricingData" />

            <article class="educational">
              <div class="row">
                  <h2 class="fw-semibold"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M5 10.0001V16.0112C5 16.3702 5 16.5496 5.05465 16.7081C5.10299 16.8482 5.18187 16.9759 5.28558 17.0817C5.40287 17.2015 5.5634 17.2818 5.88446 17.4423L11.2845 20.1423C11.5468 20.2735 11.678 20.3391 11.8156 20.3649C11.9375 20.3877 12.0625 20.3877 12.1844 20.3649C12.322 20.3391 12.4532 20.2735 12.7155 20.1423L18.1155 17.4423C18.4366 17.2818 18.5971 17.2015 18.7144 17.0817C18.8181 16.9759 18.897 16.8482 18.9453 16.7081C19 16.5496 19 16.3702 19 16.0112V10.0001M2 8.50006L11.6422 3.67895C11.7734 3.61336 11.839 3.58056 11.9078 3.56766C11.9687 3.55622 12.0313 3.55622 12.0922 3.56766C12.161 3.58056 12.2266 3.61336 12.3578 3.67895L22 8.50006L12.3578 13.3212C12.2266 13.3868 12.161 13.4196 12.0922 13.4325C12.0313 13.4439 11.9687 13.4439 11.9078 13.4325C11.839 13.4196 11.7734 13.3868 11.6422 13.3212L2 8.50006Z" stroke="#FA1F63" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg><span>Special Pricing for Schools, Universities and Non-Profits</span></h2>
                  <p>Unlock premium features at discounted rates tailored for educational institutions and non-profits. <a href="/about-us#contact">Contact us</a></p>
              </div>
            </article>
        </div>
    </section>
</template>

<style lang="scss" scoped>

.educational {
    margin-top: 24px;
    border-radius: 16px;
    border: 1px solid var(--Colors-Base-white, #FFF);
    background-color: var(--global-color-white);
    display: flex;
    padding: var(--lg, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    > .row > p {
      margin-left: 32px;
    }
    > .row > h2 > span {
      margin-left: 8px;
    }
  }

section {
    background: linear-gradient(
        180deg,
        #f4f5f7 0%,
        rgba(244, 245, 247, 1.21) 70.21%,
        rgba(244, 245, 247, 0) 102.15%
    );
}

.pricing-plan-switcher {
    background-color: var(--global-color-white);
    padding: 0.5rem !important;
    border-radius: 15rem;
    gap: 0.5rem;

    button {
        padding: 0.625rem 0.75rem;
        font-size: 0.8rem;
        color: var(--global-color-grey);
        border-radius: 15rem;
        border: 0.07rem solid transparent;
        height: 100%;

        span {
            display: inline-block;
            margin-left: 0.5rem;
            background-color: var(--global-color-primary);
            color: var(--global-color-white);
            font-size: 0.7rem;
            padding: 0.25rem 0.5rem;
            border-radius: 1rem;
        }

        &.active {
            border-color: rgba(250, 31, 99, 0.1);
            background: linear-gradient(52deg, #ffeaf0 0%, #fff 100%);
            color: var(--global-color-primary);
        }
    }
}
</style>

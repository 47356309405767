// initial state
const state = {
    player: null
};

// getters
const getters = {
};

// actions
const actions = {
};

// mutations
const mutations = {
    setPlayer(state, player) {
        state.player = player;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<script>
export default {
  name: "CustomPricingBox",
  props: {
    data: {
      type: Object,
    }
  }
}
</script>

<template>
    <article>
        <div class="row">
            <div class="col-60 col-lg-18">
                <h3 class="primary-c fw-semibold mb-2">Custom</h3>
                <p class="mb-2">
                    {{ data.description }}
                </p>

                <BaseButton
                    to="/about-us#contact"
                    type="btn-primary"
                    size="btn-big"
                    class="d-block w-100 text-center subs-pink-but"
                    >Contact Us</BaseButton>
            </div>

            <div class="col-60 col-lg-35 ms-auto pt-2">
                <h4 class="fw-semibold text-grey text-uppercase mb-2">additional services</h4>

                <ul>
                    <li v-for="(item, index) in data.benefits" :key="`cb-${index}`">
                        <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_807_11066)">
                                <path
                                    d="M5.625 9L7.875 11.25L12.375 6.75M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                                    stroke="#FA1F63"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_807_11066">
                                    <rect width="18" height="18" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        {{ item.text }}
                    </li>
                </ul>
            </div>
        </div>
    </article>
</template>

<style lang="scss" scoped>
@import '../../sass/abstracts/_mixins.scss';

article {
    border-radius: 1rem;
    background-color: var(--global-color-white);
    overflow: hidden;
    padding: 1.5rem;
    position: relative;

    h3 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }

    h4 {
        font-size: 0.8rem;
    }

    ul {
        column-count: 2;

        @include media-breakpoint-down(sm) {
            column-count: 1;
        }
    }

    ul li {
        font-size: 0.8rem;
        color: var(--global-color-grey);
        margin-bottom: 1rem;

        svg {
            margin-right: 0.6rem;
        }
    }

    .row {
        z-index: 2;
        position: relative;
    }

    p {
        font-size: 0.8rem;
    }

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(90deg, #ffecf1 0%, #fff 57%);
        z-index: 1;

        @include media-breakpoint-down(sm) {
            background: linear-gradient(90deg, #ffecf1 0%, #fff 97%);
        }
    }
}
</style>

// initial state
const state = {
    user: null,
    has: {
        channel: true,
        league: true,
        club: true,
        game: true,
        leagueteam_active: true,
    }
};

// getters
const getters = {};

// actions
const actions = {
    fetchUser({commit, rootState, state}) {
        return new Promise((resolve, reject) => {
            this.$axios.get(window.Urls["common:user_detail"]()).then((response) => {
                commit('setUser', response.data);
                resolve(response.data);
            }).catch(reason => {
                console.error(reason);
                reject(reason);
            })
        })
    }
};

// mutations
const mutations = {
    setUser(state, user) {
        state.user = user;
        state.has.channel = state.user.current_account.num_channels !== 0;
        state.has.league = state.user.current_account.num_leagues !== 0;
        state.has.club = state.user.current_account.num_clubs !== 0;
        state.has.game = state.user.current_account.num_games !== 0;
        state.has.leagueteam_active = state.user.current_account.num_teams !== 0;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
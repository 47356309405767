<script>

export default {
    name: "PricingBox",
    props: {
      data: {
        type: Object,
      },
    },
  }
</script>

<template>
    <article class="h-100 transition" v-if="data.show">
        <div class="pricing-box-header border-bottom">
            <div>
                <h3 class="fw-semibold primary-c">
                    {{ data.plan }}
                </h3>

                <p>{{ data.info }}</p>

                <span class="fw-semibold text-grey">
                     <span class="price primary-c">€{{ data.price }}</span>
                    /{{ data.period }}
                </span>

                <p v-html="data.billing"></p>

                <BaseButton
                    :ga-event="`pricing_${data.period}_${data.plan}`"
                    :to="`/${data.link.slug}/`"
                    class="d-block text-center mt-1 subs-pink-but"
                    type="btn-primary"
                    >{{ data.link.label }}</BaseButton
                >
            </div>
        </div>

        <div class="pricing-box-body">
            <ul class="mb-2" style="min-height: 220px; max-height: 250px">
                <li v-for="(item, index) in data.top_benefits" :key="`top-b/${index}`">
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_807_11066)">
                            <path
                                d="M5.625 9L7.875 11.25L12.375 6.75M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                                stroke="#FA1F63"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_807_11066">
                                <rect width="18" height="18" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span v-html="item.text"></span>
                </li>
            </ul>

            <div
                v-for="(benefits, index2) in data.other_benefits"
                :key="`other-b${index2}`"
                :class="{ 'mb-2': index2 !== data.other_benefits.length - 1 }"
            >
                <h4 class="fw-semibold mb-1">{{ benefits.title }}</h4>

                <ul :class="{ 'mb-1': benefits.additional_text }">
                  <li
                        v-for="(benefit, benefitIndex) in benefits.items"
                        :key="`benefits-${benefitIndex}`"
                        :class="{ disabled: benefit.disabled }"
                    >
                        <svg
                            v-if="!benefit.disabled"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_807_11066)">
                                <path
                                    d="M5.625 9L7.875 11.25L12.375 6.75M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                                    stroke="#FA1F63"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_807_11066">
                                    <rect width="18" height="18" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                        <svg
                            v-if="benefit.disabled"
                            width="19"
                            height="18"
                            viewBox="0 0 19 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.75 6.75L7.25 11.25M7.25 6.75L11.75 11.25M17 9C17 13.1421 13.6421 16.5 9.5 16.5C5.35786 16.5 2 13.1421 2 9C2 4.85786 5.35786 1.5 9.5 1.5C13.6421 1.5 17 4.85786 17 9Z"
                                stroke="#161616"
                                stroke-opacity="0.25"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>

                        <span v-html="benefit.text"></span>
                    </li>
                </ul>
                <small v-if="benefits.additional_text" v-html="benefits.additional_text"></small>
            </div>
        </div>
    </article>
</template>

<style lang="scss" scoped>
article {
    border-radius: 1rem;
    background-color: var(--global-color-white);
    border: 0.08rem solid transparent;
    overflow: hidden;

    &:hover {
        border-color: rgba(250, 31, 99, 0.6);

        .pricing-box-header:before {
            opacity: 1;
        }
    }
}

.pricing-box-header {
    position: relative;

    & > div {
        position: relative;
        z-index: 2;
    }

    &:before {
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #ffebf1 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 1;
        transition: opacity 0.2s ease;
        opacity: 0;
    }
}

.pricing-box-header,
.pricing-box-body {
    padding: 1.5rem;
}

h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

p {
    font-size: 0.8rem;
}

.price {
    font-size: 2.5rem;
}

ul li {
    font-size: 0.8rem;
    color: var(--global-color-grey);
    margin-bottom: 1rem;
    position: relative;
    padding-left: 1.7rem;

    &:last-child {
        margin-bottom: 0;
    }

    &.disabled {
        color: rgba(22, 22, 22, 0.25);
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
    }
}

h4 {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: #525252;
}

small {
    font-size: 0.75rem;
    color: var(--global-color-grey);
    margin-top: -0.4rem;
    display: block;
}
</style>

export function parse_cookies() {
    var cookies = {};
    if (document.cookie && document.cookie !== '') {
        document.cookie.split(';').forEach(function (c) {
            var m = c.trim().match(/(\w+)=(.*)/);
            if (m !== undefined && m != null) {
                cookies[m[1]] = decodeURIComponent(m[2]);
            }
        });
    }
    return cookies;
}

export function getOriginalId(hash) {
    return parseInt(window.atob(hash).split(':')[1]);
}

export function pathJoin(parts) {
    let result = parts.map((part, i) => {
        if(part === undefined || part === null){
            return ''
        }
        if (i === 0) {
            return part.trim().replace(/[\/]*$/g, '')
        } else {
            return part.trim().replace(/(^[\/]*|[\/]*$)/g, '')
        }
    }).filter(x => x.length).join('/')

    if(parts[0] === '/' && parts[0].length === 1){
        result = '/' + result
    }
    return result
}

export function getQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

export var filterable_select_fix = {
    mounted: function () {
        [].forEach.call(this.$el.querySelectorAll('.el-select .el-input__inner'), function (node, index) {
            node.removeAttribute('readonly')
        });
    },
    methods: {
        selectFix() {
            [].forEach.call(this.$el.querySelectorAll('.el-select .el-input__inner'), function (node,index) {
                node.removeAttribute('readonly')
            });
        }
    }
};

export const AccountLevelTypes = Object.freeze({
    CLUB_OR_ATHLETE: 1,
    FEDERATION_OR_ASSOCIATION: 2
});
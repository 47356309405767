<script>
export default {
  name: "PricingBoxes",
  props: {
    data: {
      type: Array,
    }
  }
}
</script>

<template>
  <div class="row g-1 justify-content-center">
    <div v-for="(item, index) in data" :key="`pr-b${index}`" class="col-60 col-lg-15" :class="{'col-lg-20': data.length < 4}">
        <PricingBox :data="item" />
    </div>
  </div>
</template>

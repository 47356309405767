import Vue from "vue";
import numeral from "numeral";
import moment from "moment";

Vue.filter('format_views', function (value, format_value = '0 a') {
    return numeral(value).format(format_value)
});

Vue.filter('format_publish_date', function (iso_date) {
    let date = Date.parse(iso_date);
    let difference = moment(date).diff(new Date(), 'days');
    if (difference >= 7) {
        return moment(date).format(date, 'MMM D, YYYY at H:mm A')
    } else {
        return moment(date).fromNow();
    }
});

Vue.filter('format_date', function (iso_date, format_date) {
    let date = Date.parse(iso_date);
    return moment(date).format(format_date);
});

Vue.filter('format_local_number', function (number) {
    return number.toLocaleString('en');
});
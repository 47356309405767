import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import pick from 'lodash/pick'
import Vue from 'vue'
import {apolloClient} from '../../graphql'
import gql from 'graphql-tag'

// initial state
const state = {
    result_names: [],
    search_data: {},
    result: {},
    request_count: 0,
    default_structure: {
        index_name: '',
        filter: {},
        filter_range: {},
        full_text: {},
        full_text_boost: {},
        sort: {},
        from: 0,
        size: 1
    },
    initCountrySelect: true,
    country: '',
    popular_sports_country: '',
    all_countries: null,
    filter_countries: null,
    all_sports: null
};

// getters
const getters = {
    getSortItem: (state) => (result_name, index) => {
        return state.search_data[result_name].sort[index]
    }
};

function getFilterItemsQuery() {
    return gql`{
            allCountries {
                id,
                code,
                flag,
                name,
                slug,
                disableRegistration,
                visibleInFilter,
                prefix
            }
            allSports {
                id,
                name,
                icon,
                slug,
                url
            }
         }`
}

function change_filter(commit, state, new_filter, result_action = 'setResult', create_call = !state.initCountrySelect) {
    commit('setFilter', new_filter);
    let search_data_specific = cloneDeep(pick(state.search_data, ...Object.keys(new_filter)));
    if (result_action == 'setResult') {
        Object.keys(new_filter).forEach((result_name) => {
            search_data_specific[result_name].from = 0;
        });
    }
    if (create_call) {
        commit('incrementRequestCount');
        axios.post(window.urls.searchApi, {search_data: search_data_specific})
            .then(function (response) {
                for (var result_name in response.data) {
                    let hits = response.data[result_name].hits;
                    if (response.data[result_name].hasOwnProperty('aggregations')) {
                        hits['aggregations'] = response.data[result_name].aggregations;
                    }
                    commit(result_action, {
                        result_name,
                        hits
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }
}

// actions
const actions = {
    loadMore({commit, state}, new_filter) {
        change_filter(commit, state, new_filter, 'appendResult');
    },
    resetLoadMore({commit, state}, new_filter) {
        change_filter(commit, state, new_filter, 'setResult');
    },
    setFilter({commit, state}, new_filter) {
        change_filter(commit, state, new_filter);
    },
    setFilterNoCall({commit, state}, new_filter) {
        change_filter(commit, state, new_filter, 'setResult', false);
    },
    setCountry({commit, state}, {country, result_names}) {
        commit('setCountry', country);
        result_names.forEach((result_name) => {
            if (state.search_data.hasOwnProperty(result_name)) {
                commit('setCountryForFilter', {country: country, result_name: result_name});
            }
        });
        if (!state.initCountrySelect || state.request_count == 0) {
            change_filter(commit, state, state.search_data, 'setResult', true);
        }
        commit('setInitCountrySelect', false);
    },
    async fetchAllSportsCountries({commit, state}) {
        if (state.all_countries != null && state.all_sports != null) {
            return
        }
        const response = await apolloClient.query({
            query: getFilterItemsQuery()
        });
        commit('setAllSportCountries', response.data);
    }
};

// mutations
const mutations = {
    incrementRequestCount(state) {
        state.request_count += 1;
    },
    setInitCountrySelect(state, value) {
        state.initCountrySelect = value;
    },
    setFilter(state, new_filter) {
        for (var result_name in new_filter) {
            Vue.set(state.search_data, result_name, {
                ...state.search_data[result_name],
                ...new_filter[result_name]
            });
            Vue.set(state.result, result_name, {
                ...state.result[result_name],
                loading: true
            });
        }
    },
    setCountry(state, country) {
        state.country = country;
        state.popular_sports_country = country;
    },
    setAllSportCountries(state, data) {
        state.all_sports = data.allSports;
        state.all_countries = data.allCountries;
        state.filter_countries = data.allCountries.filter(x => !x.disableRegistration).map((item) => {
                        return {
                            ...item,
                        }
                    });
    },
    setCountryForFilter(state, {country, result_name}) {
        if (state.initCountrySelect) {
            if (state.search_data[result_name].filter.country_slug == '' || state.search_data[result_name].filter.country_slug == null) {
                state.search_data[result_name].filter.country_slug = country;
            }
        } else {
            state.search_data[result_name].filter.country_slug = country;
        }

    },
    setCountryForPopularSports(state, country) {
        state.popular_sports_country = country;
    },
    setSearchIndexes(state, {result_names, index_names}) {
        state.result_names = [...state.result_names, ...result_names];

        result_names.forEach((result_name, i) => {
            let default_structure = {
                ...state.default_structure,
                index_name: index_names[i]
            };
            if (!state.search_data.hasOwnProperty(result_name)) {
                Vue.set(state.search_data, result_name, default_structure);
            }
            if (!state.result.hasOwnProperty(result_name)) {
                Vue.set(state.result, result_name, {loading: true});
            }
        });
    },
    setResult(state, {result_name, hits}) {
        Vue.set(state.result, result_name, {
            ...hits,
            loading: false
        });
    },
    appendResult(state, {result_name, hits}) {
        Vue.set(state.result, result_name, {
            total: hits.total,
            hits: [
                ...state.result[result_name].hits,
                ...hits.hits
            ],
            loading: false
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import Vue from 'vue'
import 'es6-promise/auto'
import Vuex from 'vuex'
import filter_store from './modules/filter_store'
import video_player from "./modules/video_player";
import global_data from "./modules/global_data";
import auth from "./modules/auth";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        global_data,
        filter_store,
        video_player,
        auth
    },
    strict: debug
})